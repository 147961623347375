import { gql } from "@apollo/client";

const ACCOUNT = gql`
  query account {
    userAccount {
      profile {
        id
        name
        handle
        profilePhoto {
          id
          filename
        }
      }
    }
  }
`;

export default ACCOUNT;
