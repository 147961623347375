import { useRef, useEffect } from "react";

function useAlerter(ref, cb) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        cb();
      }
    }

    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, cb]);
}

function ClickOutside({ children, onClickOutside = () => {} }) {
  const wrapperRef = useRef(null);
  useAlerter(wrapperRef, onClickOutside);

  return children(wrapperRef);
}

export default ClickOutside;
