import { gql } from "@apollo/client";

const EVENT_TYPES = gql`
  query eventTypesQuery {
    eventTypes {
      id
      name
    }
  }
`;

export default EVENT_TYPES;
