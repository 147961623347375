import "./YouTubeVideo.css";

function YouTubeVideo({ height = 480, width = 853, filename, noControls }) {
  return (
    <div className="youtube-video">
      <iframe
        width={width}
        height={height}
        src={noControls ? filename + "?controls=0" : filename}
        frameBorder="0"
        allow="accelerometer; autoplay; modestbranding; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  );
}

export default YouTubeVideo;
